<template>

<body>
    <!-- ::::::  Start Header Section  ::::::  -->
    <Header />
    <!-- :::::: End Header Section ::::::  -->  

    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav">
                            <router-link to="/">Home</router-link>
                            </li>
                        <li class="page-breadcrumb__nav active">About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->
    <!-- ::::::  Start  Main Container Section  ::::::  -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="about-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="about-img">
                            <div class="img-responsive">
                                <img :src="$root.URL_ROOT+'uploads/pages/'+pageData.image" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="about-content">
                            <h4 class="font--regular">{{pageData.heading}}</h4>
                            <p class="para__text" v-html="pageData.pbody"></p>
                        </div>
                    </div>
                </div>
            </div>         
        </div>
        <!-- ::::::  Start CMS Section  ::::::  -->
        <div class="cms m-t-100">
            <div class="container">
                <div class="row">
                    <!-- Start Single CMS box -->
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="cms__content">
                            <div class="cms__icon">
                                <i class="fas fa-truck"></i>
                            </div>
                            <div class="cms__text">
                                <h6 class="cms__title">Free Shipping</h6>
                                <p class="para__text">Erat metus sodales eget dolor consectetuer, porta ut purus at et alias, nulla ornare velit amet</p>
                            </div>
                        </div>
                    </div> <!-- End Single CMS box -->
                    <!-- Start Single CMS box -->
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="cms__content">
                            <div class="cms__icon">
                                <i class="far fa-money-bill-alt"></i>
                            </div>
                            <div class="cms__text">
                                <h6 class="cms__title">100% Money Back Guarantee</h6>
                                <p class="para__text">Erat metus sodales eget dolor consectetuer, porta ut purus at et alias, nulla ornare velit amet</p>
                            </div>
                        </div>
                    </div> <!-- End Single CMS box -->
                    <!-- Start Single CMS box -->
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="cms__content">
                            <div class="cms__icon">
                                <i class="far fa-life-ring"></i>
                            </div>
                            <div class="cms__text">
                                <h6 class="cms__title">Online Support 24/7</h6>
                                <p class="para__text">Erat metus sodales eget dolor consectetuer, porta ut purus at et alias, nulla ornare velit amet</p>
                            </div>
                        </div>
                    </div> <!-- End Single CMS box -->
                </div>
            </div>
        </div> <!-- ::::::  End CMS Section  ::::::  -->
        
       <!-- ::::::  Start  Company Logo Section  ::::::  -->
       <!-- <Partners/> -->
       <!-- ::::::  End  Company Logo Section  ::::::  -->

     

       
    </main> <!-- ::::::  End  Main Container Section  ::::::  -->

    <!-- ::::::  Start  Footer ::::::  -->
   <Footer/>
   <!-- ::::::  End  Footer ::::::  -->
    
   
</body>
</template>

<script>
import Header from '@/components/navbar'
import Footer from '@/components/footer'
import Testimonial from '@/components/Testimonial'
import Partners from '@/components/Partners'
import carousel2 from "vue-owl-carousel";
import carousel from 'vue-owl-carousel2'
export default {
    props: ["catedatas"],
    components: {
        Header,
        Footer,
        carousel,
        carousel2,
        Partners,
        Testimonial
    },
    data() {
        return {
            
            pageData: [],
            fields: {
                type: 'update_profile',
                uid: '',
                fullname: '',
                mobile: '',
                address: '',
                Token: this.$root.auth
            }
        }
    },
    mounted() {
        this.uid = localStorage.getItem('user')
        this.pData()
        this.goToTop()
    },
    methods: {
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        pData() {
            const axios = require('axios')
            var self = this
            axios.post(this.$root.URL_ROOT + 'api.php', {
                type:'aboutus'
            }).then(function (response) {
                var data = response.data
                self.pageData = data.data
                console.log(response)
            })
        }
    }
}
</script>
